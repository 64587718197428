import { render, staticRenderFns } from "./varietyList.vue?vue&type=template&id=1ed4deed&scoped=true"
import script from "./varietyList.vue?vue&type=script&lang=js"
export * from "./varietyList.vue?vue&type=script&lang=js"
import style0 from "./varietyList.vue?vue&type=style&index=0&id=1ed4deed&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ed4deed",
  null
  
)

export default component.exports